@import url("https://fonts.googleapis.com/css2?family=Skranji:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* TODO light theme to new color palette */
  :root {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --slayercard: 217.2 32.6% 17.5%;
    --slayercard-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --tertiary: 20.2 74.8% 58.8%;
    --tertiary-foreground: 20.2 74.8% 58.8%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 50.6%;
    --destructive-foreground: 210 40% 98%;

    --success: 120 35% 40%;
    --success-foreground: 75 9.1% 97.8%;

    --border: 217.2 12% 25.5%;
    --input: 20.2 74.8% 58.8%;
    --ring: 20.2 74.8% 58.8%;

    --radius: 0.5rem;
  }

  /* changed dark theme to new color palette */
  .dark,
  [data-theme="dark"] {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --slayercard: 217.2 32.6% 17.5%;
    --slayercard-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --tertiary: 20.2 74.8% 58.8%;
    --tertiary-foreground: 20.2 74.8% 58.8%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 50.6%;
    --destructive-foreground: 210 40% 98%;

    --success: 120 35% 40%;
    --success-foreground: 75 9.1% 97.8%;

    --border: 217.2 12% 25.5%;
    --input: 20.2 74.8% 58.8%;
    --ring: 20.2 74.8% 58.8%;

    --radius: 0.5rem;
  }
}

/* Removed hover and text effects, added smooth scroll behavior and bg / text color + font thickness */
@layer base {
  * {
    @apply border-border;
  }
  html {
    @apply scroll-smooth;
    @apply antialiased;
  }
  body {
    @apply bg-background text-foreground font-thin;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
